// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./email.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "./email--inverted.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "./facebook.svg";
import ___CSS_LOADER_URL_IMPORT_3___ from "./facebook--inverted.svg";
import ___CSS_LOADER_URL_IMPORT_4___ from "./native.svg";
import ___CSS_LOADER_URL_IMPORT_5___ from "./native--inverted.svg";
import ___CSS_LOADER_URL_IMPORT_6___ from "./twitter.svg";
import ___CSS_LOADER_URL_IMPORT_7___ from "./twitter--inverted.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShareLink{position:relative;flex:1 1 2.3125rem;margin:0;border:0;padding:0;width:2.3125rem;background-color:#b3b3b3}.Share .ShareLink{transition:filter .25s}.Share .ShareLink:hover{-webkit-filter:brightness(0.95);filter:brightness(0.95)}.ShareLink::after{content:\"\";position:absolute;top:0;left:0;width:100%;height:100%;background-position:center;background-repeat:no-repeat;background-size:auto 100%}.ShareLink+.ShareLink{margin-left:.1875rem}@media(max-width: 43.6875rem){.ShareLink--email{display:none}}.ShareLink--email::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.u-richtext-invert .ShareLink--email::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.ShareLink--facebook::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.u-richtext-invert .ShareLink--facebook::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.ShareLink--native::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.u-richtext-invert .ShareLink--native::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.ShareLink--twitter::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.u-richtext-invert .ShareLink--twitter::after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
