// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./close.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html.is-master-gallery-open{overflow:hidden}.MasterGallery{display:none;flex-direction:column;justify-content:center;position:fixed;z-index:3;top:0;left:0;margin-top:0;margin-bottom:0;padding-top:0;padding-right:0;padding-left:0;width:100%;height:100%;background-color:rgba(0,0,0,.93)}html.is-master-gallery-open .MasterGallery{display:flex}.MasterGallery .Gallery{opacity:0;margin:0 auto;will-change:opacity}html.is-master-gallery-open .MasterGallery .Gallery{opacity:1;animation:fadeIn}.MasterGallery .Gallery-layout{padding:0;width:100%;max-width:133vh}.MasterGallery .Gallery-controls{padding:0}.MasterGallery .Gallery-item>:not(.Caption){max-width:100%;height:auto}.MasterGallery .Caption{margin:1rem auto;width:100%;max-width:59.5rem}@media(min-width: 61.25rem){.MasterGallery .Caption{font-size:1rem !important;line-height:1.625rem}.MasterGallery .Caption .Caption-attribution{display:block;font-size:.8125rem !important}}.MasterGallery-container{overflow:auto}.MasterGallery-close{position:absolute;z-index:1;top:0;right:0;padding:0;border:none;width:2.5rem;height:2.5rem;background-color:rgba(0,0,0,.6);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-repeat:no-repeat;background-size:100%;vertical-align:bottom}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
